* {
  padding: 0;
  margin: 0;
  cursor: none;
}

body, html  {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  position: relative !important;
  overflow-x: hidden !important;
}

.black-bg-bg {
  width: 100vw;
  height: 7250px !important;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  -webkit-overflow-scrolling: touch;

}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 3px;

}

::-webkit-scrollbar-thumb {
  background-color: rgb(209, 209, 209);
  border-radius: 10px;
}