.background {
    mix-blend-mode: normal;
    opacity: 1;
    filter:saturate(1.1);
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    transition: transform 1s cubic-bezier(0.16, 0.69, 0.26, 0.88), opacity 1s ease-in-out;
    background-color: transparent !important;
    opacity: 1;
  }
  
  svg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  .out-top {
    animation: rotate 40s linear infinite;
    transform-origin: 13px 25px;
  }
  
  .in-top {
    animation: rotate 20s linear infinite;
    transform-origin: 13px 25px;
  }
  
  .out-bottom {
    animation: rotate 50s linear infinite;
    transform-origin: 84px 93px;
  }
  
  .in-bottom {
    animation: rotate 30s linear infinite;
    transform-origin: 84px 93px;
  }
  
  .out-top,
  .in-top,
  .out-bottom,
  .in-bottom {
    transition: fill 1s ease-in-out;
  }
  
  .background,
  body {
    transition: background-color 1s ease-in-out;
  }
  
  .svgTop,
  .svgBottom {
    position: absolute !important;
    transition: top 2s cubic-bezier(0.28, 0.94, 0.16, 1) 1s,
      left 2s cubic-bezier(0.28, 0.94, 0.16, 1) 1s;
    left: -100vw;
    top: -100vw;
  }
  
  .svgBottom {
    top: 100vw;
    left: 100vw;
  }