.project-page {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  background-color: #fbf8f3;
  text-align: center;
  opacity: 0;
  transition: opacity 0.55s ease-in-out, transform 1.5s ease-in-out;
  align-items: center;
}
.project-page div {
  position: relative;
  top: 5vh;
  font-family: "Neucha", sans-serif;
  font-size: 8vw;
}
.temp-text {
  position: absolute;
  top: 33vh;
  font-family: "Neucha", sans-serif;
  font-size: 1.2em;
  left: 10vw;
}
.project-page .letter {
  display: inline-block;
}
.project-page .separator {
  width: 80vw;
  top: 10vh;
  background-color: black;
  height: 1px;
  left: 10vw;
}
.project-page .lines {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 10vh;
}
.project-page .lines div {
  width: 80vw;
  height: 1px;
  background-color: black;
  opacity: 0.5;
  margin: 0.08em 0;
}
.project-page .exit {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 3vw;
  right: 3vw;
  border-radius: 100%;
  overflow: hidden;
}
.project-page .exit div {
  width: 25px;
  height: 1px;
  background-color: #fbf8f3;
  transition: background-color 1.5s ease-in-out, transform 1.5s cubic-bezier(0.37, 1.45, 0.16, 1.08) 0.12s;
  position: absolute;
  left: 37.5px;
  z-index: 2;
  top: 50px;
  rotate: 45deg;
}
.project-page .exit div:nth-child(2) {
  rotate: -45deg;
}
.project-page .exit div:nth-child(3) {
  background-color: black;
  width: 98px;
  height: 98px;
  top: 1px;
  border-radius: 100%;
  z-index: 0;
  left: 1px;
}
.project-page .exit .circle {
  background-color: #fbf8f3;
  transform: scale(0);
  width: 5px;
  height: 5px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.73s ease-in-out;
  z-index: 1;
}
.project-page.peel {
  transform: translateX(-99vw) translateY(-100vh) rotate3d(.3, 1, .3, -110deg);
}


.circle-2 {
  position: absolute !important;
}
.pageflip {
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: inherit;
  bottom: 0;
  right: 0;
  transition: width cubic-bezier(0.25, 0.85, 0.4, 0.96) 1s, height cubic-bezier(0.25, 0.85, 0.4, 0.96) 1s;
}
.pageflip:hover {
  width: 130px;
  height: 130px;
}
.pgf {
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
}