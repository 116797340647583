@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&family=Kotta+One&family=Krona+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0;
  margin: 0; }

body {
  background-color: #232323;
  height: 400vh; }

.title-container2 {
  top: calc(235vh + 400px);
  opacity: 0 !important;
  pointer-events: all !important;
}
.title-container {
  width: 100vw;
  height: 150px;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 0;
  transition: opacity .78s ease-in-out;
  top: 100vh; }
  .title-container div {
    width: 100%;
    height: 50%;
    background: linear-gradient(#ffffff00, #ffffffff); }
    .title-container div:nth-child(2) {
      background: linear-gradient(#ffffffff, #ffffff00); }
  .title-container p {
    font-family: "Krona One", sans-serif;
    font-size: 7em;
    color: black;
    position: absolute;
    top: -4px;
    left: 0;
    white-space: nowrap; }
    .title-container p:nth-child(4), .title-container p:nth-child(5) {
      top: -40px;
      left: -330px;
      transform: rotate(180deg);
      background: linear-gradient(#ffffffff 0%, #ffffff00 70%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      pointer-events: none; }
    .title-container p:nth-child(5) {
      top: 50px;
      transform: rotate(180deg) scaleY(-1);
      background: linear-gradient(#ffffffff 0%, #ffffff00 70%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      left: -330px;
      display: inline-block; }

      .title-reenter {
        font-size: 1em !important;
        text-align: center !important;
        top: 130px !important;
        left: calc(50% - 101px) !important;
        color: white !important;
      }

.title-main {
  z-index: 1;
}