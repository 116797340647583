.project-page
  width: 100vw
  height: 100vh
  overflow: hidden
  position: absolute
  background-color: #fbf8f3
  text-align: center
  opacity: 0
  transition: opacity .55s ease-in-out
  align-items: center
  div
    position: relative
    top: 5vh
    font-family: "Neucha", sans-serif
    font-size: 8vw
  .letter
    display: inline-block
  .separator
    width: 80vw
    top: 10vh
    background-color: black
    height: 1px
    left: 10vw
  .lines
    display: flex
    flex-direction: column
    align-items: center
    top: 10vh
    div
      width: 80vw
      height: 1px
      background-color: black
      opacity: .5
      margin: .08em 0
  .exit
    width: 100px
    height: 100px
    position: absolute
    top: 3vw
    right: 3vw
    border-radius: 100%
    overflow: hidden
    div
      width: 25px
      height: 1px
      background-color: #fbf8f3
      transition: background-color 1.5s ease-in-out, transform 1.5s cubic-bezier(.37,1.45,.16,1.08) .12s
      position: absolute
      left: calc(50px - 0.5 * 25px)
      z-index: 2
      top: 50px
      rotate: 45deg
      &:nth-child(2)
        rotate: (-45deg)
      &:nth-child(3)
        background-color: black
        width: 98px
        height: 98px
        top: calc(50px - 0.5 * 98px)
        border-radius: 100%
        z-index: 0
        left: calc(50px - 0.5 * 98px)
    .circle
      background-color: #fbf8f3
      transform: scale(0)
      width: 5px
      height: 5px
      border-radius: 100%
      position: absolute
      top: 0
      left: 0
      transition: transform .73s ease-in-out
      z-index: 1


